* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}
.hide {
  display: none;
}
.dropdown-content {
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  overflow: auto;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}
.upload_document {
  display: block;
  width: 100%;
  position: absolute;
  cursor: pointer;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
}

.react-datepicker-wrapper {
  width: 100% !important;
}
.react-datepicker__input-container > * {
  min-height: 55px !important;
  display: block !important;
  border: 1px solid #cccccc !important;
  width: 100% !important;
  padding: 8px 16px !important;
  border-radius: 16px !important;
}
.bulk_upload {
  display: block;
  width: 100%;
  position: absolute;
  cursor: pointer;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
}

.upload_image_btn {
  /* color: transparent; */
  /* padding: 2px !important; */
  /* display: none; */
}
.upload_image_btn::-webkit-file-upload-button {
  visibility: hidden;
}
/* .upload_image_btn::before {
  content: "Upload image";
  background-color: #f2f4fd;
  color: #0b0a0a;
  display: inline-block;
  border: 1px solid #007da3;
  padding: 8px 8px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  font-size: 14px;
  width: 150px;
  text-align: center;
} */
.upload_image_btn:hover::before {
  border-color: #081630;
}
.upload_image_btn:active {
  outline: 0;
}
.upload_image_btn:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}
.profile input[type="file"] {
  /* border: 0px !important; */
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  background: none;
  height: auto;
  height: fit-content;
  padding: 30px;
}
.paginateWrapper {
  display: flex;
  align-items: center;
  justify-content: end;
  /* border: 1px solid red; */
}
.paginateWrapper {
  list-style-type: none;
  display: flex;
  gap: 10px;
  margin-top: 2em;
}
.paginateWrapper li a {
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  position: relative;
  padding: 0.5em 1em;
}

.paginateWrapper li.active a {
  background-color: #f0f0f0;
}
.paginateWrapper li.disabled {
  display: none;
}
.PhoneInput {
  padding: 12px 16px;
  border: 1px solid #e2e8f0 !important;
  border-radius: 16px !important;
}
.PhoneInputInput {
  /* border-left: 1px solid #e2e8f0 !important; */
  outline: none !important;
  /* padding-left: 10px; */
  /* margin-left: 10px; */
}
.PhoneInputCountry {
  display: none !important;
}
.active p {
  color: #039855 !important;
}
.active svg {
  stroke: #039855 !important;
}
.inactive svg {
  stroke: #a3a3a3 !important;
}
.phone-num-input .form-control {
  width: 100% !important;
  padding: 12px 16px;
  border: 1px solid #e2e8f0 !important;
  border-radius: 16px !important;
  height: 50px !important;
}
.phone-num-input .flag-dropdown {
  border-top-left-radius: 14px !important;
  border-bottom-left-radius: 14px !important;
}
.isError {
  border: 2px solid red !important;
}
.upper-alpha {
  list-style-type: upper-alpha;
}
.lower-alpha {
  list-style-type: lower-alpha;
}
.roman-upper {
  list-style-type: lower-roman;
}
.roman-upper li {
  font-size: 14px;
  padding-bottom: 10px;
}
.upper-alpha li {
  font-size: 14px;
  padding-bottom: 10px;
}
.lower-alpha li {
  font-size: 14px;
  padding-bottom: 10px;
}
